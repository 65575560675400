<template>
  <div class="content">
    <div class="card">
      <img class="logo" src="../../assets/images/logo.png"/>
      <p class="title">パスワードを忘れましたか？</p>

      <p class="desc">メールアドレス</p>

      <input v-model="value" placeholder=""/>

      <div @click="reset" class="mail">パスワードを再発行する</div>

      <div @click="nav()" class="login">
        <span>ログイン</span>
      </div>
    </div>


    <admin-footer></admin-footer>
  </div>
</template>

<script>
import adminFooter from '@/components/adminFooter.vue'
import {Message} from "element-ui";

export default {
  name: "modify",

  components: {
    adminFooter
  },

  data() {
    return {
      value: ''
    }
  },

  methods: {
    nav() {
      this.$router.push('/login')
    },

    async reset() {
      let res = await this.$axios({url: `/compUser/resetPwd/${this.value}`, data: {}, method: "post"});

      if(res.code == '000') {
        // this.$message
        Message({
          showClose: true,
          message: 'パスワードを再発行しました',
          type: "success"
        });
      }
    }
  }
}
</script>

<style scoped src="../../style/modify.css">


</style>
